import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Image from '../components/image'
import Layout from '../components/layout'
import SEO from "../components/seo"
import Reactmarkdown from "react-markdown"

const ArticleTemplate = ({ data }) => (
  <Layout>
    <SEO title={data.strapiArticle.Title} description={data.strapiArticle.excerpt} />
    <div className="container py-6">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <h1>{data.strapiArticle.Title}</h1>
          <hr className="border-1 border-light my-4 ml-0 text-left w-15" role="presentation" />
          <div className="d-flex align-items-center mb-3">
            <Image className="avatar avatar-sm mr-3" />
            <div className="font-italic text-light">by <Link to='/' className="text-white">{data.strapiArticle.author.username}</Link></div>
            <div className="text-secondary ml-auto">{data.strapiArticle.published_on || data.strapiArticle.created_at}</div>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-white text-black">
      <div className="container py-2">
        <div className="bg-white mt-n6 px-3 px-lg-0">
          <div className="row justify-content-center py-4 py-lg-5">
            <div className="col-md-10 col-lg-8">
              <Img className="mb-3" fluid={data.strapiArticle.Image.childImageSharp.fluid} />
              <Reactmarkdown
                transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`}
                source={data.strapiArticle.Content} />
              <hr className="my-4" />
              <h3 className="sr-only">Tags</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ArticleTemplate

export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiArticle(id: {eq: $id}) {
      Title
      Content
      excerpt
      created_at(formatString: "DD MMMM YYYY")
      published_on(formatString: "DD MMMM YYYY")
      Image {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        author {
          id
          username
        }
    }
  }
`